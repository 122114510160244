// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-countries-js": () => import("./../../../src/pages/countries.js" /* webpackChunkName: "component---src-pages-countries-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noses-js": () => import("./../../../src/pages/noses.js" /* webpackChunkName: "component---src-pages-noses-js" */),
  "component---src-pages-notes-js": () => import("./../../../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-perfumes-index-js": () => import("./../../../src/pages/perfumes/index.js" /* webpackChunkName: "component---src-pages-perfumes-index-js" */),
  "component---src-pages-perfumes-new-js": () => import("./../../../src/pages/perfumes/new.js" /* webpackChunkName: "component---src-pages-perfumes-new-js" */),
  "component---src-pages-perfumes-sale-js": () => import("./../../../src/pages/perfumes/sale.js" /* webpackChunkName: "component---src-pages-perfumes-sale-js" */),
  "component---src-pages-pyramid-js": () => import("./../../../src/pages/pyramid.js" /* webpackChunkName: "component---src-pages-pyramid-js" */),
  "component---src-pages-seasons-js": () => import("./../../../src/pages/seasons.js" /* webpackChunkName: "component---src-pages-seasons-js" */),
  "component---src-pages-sitemap-accords-js": () => import("./../../../src/pages/sitemap/accords.js" /* webpackChunkName: "component---src-pages-sitemap-accords-js" */),
  "component---src-pages-sitemap-brands-js": () => import("./../../../src/pages/sitemap/brands.js" /* webpackChunkName: "component---src-pages-sitemap-brands-js" */),
  "component---src-pages-sitemap-countries-js": () => import("./../../../src/pages/sitemap/countries.js" /* webpackChunkName: "component---src-pages-sitemap-countries-js" */),
  "component---src-pages-sitemap-families-js": () => import("./../../../src/pages/sitemap/families.js" /* webpackChunkName: "component---src-pages-sitemap-families-js" */),
  "component---src-pages-sitemap-index-js": () => import("./../../../src/pages/sitemap/index.js" /* webpackChunkName: "component---src-pages-sitemap-index-js" */),
  "component---src-pages-sitemap-noses-js": () => import("./../../../src/pages/sitemap/noses.js" /* webpackChunkName: "component---src-pages-sitemap-noses-js" */),
  "component---src-pages-sitemap-notes-js": () => import("./../../../src/pages/sitemap/notes.js" /* webpackChunkName: "component---src-pages-sitemap-notes-js" */),
  "component---src-pages-sitemap-perfumes-js": () => import("./../../../src/pages/sitemap/perfumes.js" /* webpackChunkName: "component---src-pages-sitemap-perfumes-js" */),
  "component---src-pages-sitemap-pyramid-js": () => import("./../../../src/pages/sitemap/pyramid.js" /* webpackChunkName: "component---src-pages-sitemap-pyramid-js" */),
  "component---src-pages-sitemap-seasons-js": () => import("./../../../src/pages/sitemap/seasons.js" /* webpackChunkName: "component---src-pages-sitemap-seasons-js" */),
  "component---src-pages-sitemap-times-of-day-js": () => import("./../../../src/pages/sitemap/times-of-day.js" /* webpackChunkName: "component---src-pages-sitemap-times-of-day-js" */),
  "component---src-pages-sitemap-years-js": () => import("./../../../src/pages/sitemap/years.js" /* webpackChunkName: "component---src-pages-sitemap-years-js" */),
  "component---src-pages-times-of-day-js": () => import("./../../../src/pages/times-of-day.js" /* webpackChunkName: "component---src-pages-times-of-day-js" */),
  "component---src-pages-years-js": () => import("./../../../src/pages/years.js" /* webpackChunkName: "component---src-pages-years-js" */),
  "component---src-templates-accord-js": () => import("./../../../src/templates/accord.js" /* webpackChunkName: "component---src-templates-accord-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-clock-js": () => import("./../../../src/templates/clock.js" /* webpackChunkName: "component---src-templates-clock-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-family-js": () => import("./../../../src/templates/family.js" /* webpackChunkName: "component---src-templates-family-js" */),
  "component---src-templates-fragrance-js": () => import("./../../../src/templates/fragrance.js" /* webpackChunkName: "component---src-templates-fragrance-js" */),
  "component---src-templates-layer-js": () => import("./../../../src/templates/layer.js" /* webpackChunkName: "component---src-templates-layer-js" */),
  "component---src-templates-markdown-js": () => import("./../../../src/templates/markdown.js" /* webpackChunkName: "component---src-templates-markdown-js" */),
  "component---src-templates-nose-js": () => import("./../../../src/templates/nose.js" /* webpackChunkName: "component---src-templates-nose-js" */),
  "component---src-templates-note-js": () => import("./../../../src/templates/note.js" /* webpackChunkName: "component---src-templates-note-js" */),
  "component---src-templates-season-js": () => import("./../../../src/templates/season.js" /* webpackChunkName: "component---src-templates-season-js" */),
  "component---src-templates-year-js": () => import("./../../../src/templates/year.js" /* webpackChunkName: "component---src-templates-year-js" */)
}

